import { UAContext } from '@quentin-sommer/react-useragent';
import React, { useContext } from 'react';
import Typography from '~components/typography';
import { ShareEmail, SharePhone } from '~svg-components';
import styles from './modal-content-width-contact.module.scss';

interface IModalContentWithContractProps {
  title?: string;
  email: string | undefined | null;
  areaCode: string | undefined | null;
  phone: string | undefined | null;
  variant?: any;
}

const ModalContentWithContract: React.FC<IModalContentWithContractProps> = ({
  title,
  email,
  areaCode,
  phone,
  variant,
}) => {
  const uaResults = useContext(UAContext).uaResults as UAResults;
  const isMobile = uaResults.mobile;

  return (
    <div className="root">
      <Typography
        component="p"
        variant={isMobile ? 'body3' : 'body1'}
        color="var(--color-purple_6)"
        style={{ marginTop: 8 }}
      >
        {title}
      </Typography>
      {email && (
        <div className={styles.contact}>
          <ShareEmail className={styles.icon} width={16} height={12} fill="var(--color-greyText)" />
          <Typography variant={variant ? variant : isMobile ? 'body3' : 'body1'}>
            {email}
          </Typography>
        </div>
      )}
      {areaCode && phone && (
        <div className={styles.contact}>
          <SharePhone className={styles.icon} width={16} height={16} fill="var(--color-greyText)" />
          <Typography variant={variant ? variant : isMobile ? 'body3' : 'body1'}>
            {areaCode} {phone}
          </Typography>
        </div>
      )}
    </div>
  );
};

export default ModalContentWithContract;
