import { UAContext } from '@quentin-sommer/react-useragent';
import classNames from 'classnames';
import React, { useContext } from 'react';
import ReactModal from 'react-modal';
import Typography from '~components/typography';
import { Close } from '~svg-components';

interface ModalProps {
  isOpen: boolean;
  title?: string | React.ReactNode;
  notShowTitleBlank?: boolean;
  titleAlign?: 'left' | 'center' | 'right';
  mobileHeightType?: 'auto' | 'full' | 'broken';
  bottomContent?: React.ReactNode;
  onClose?: () => void;
  className?: string;
  overlayClassName?: string;
  onRequestClose?: Function;
  wrapperStyle?: any;
  childrenWrapperStyle?: any;
  titleEmptyStyle?: any;
  style?: any;
}

const BaseModal: React.FunctionComponent<ModalProps> = props => {
  const uaResults = useContext(UAContext).uaResults as UAResults;
  const isMobile = uaResults.mobile;
  const {
    isOpen,
    onClose,
    mobileHeightType = 'full',
    title,
    children,
    bottomContent,
    titleAlign = 'center',
    className,
    notShowTitleBlank,
    overlayClassName,
    onRequestClose,
    wrapperStyle,
    titleEmptyStyle,
    childrenWrapperStyle,
    style,
  } = props;

  return (
    <>
      <ReactModal
        isOpen={isOpen}
        className="base-modal__content"
        overlayClassName={classNames('base-modal__overlay', overlayClassName)}
        ariaHideApp={false}
        preventScroll={true}
        onRequestClose={() => {
          if (onClose) {
            onClose();
          }
          if (onRequestClose) {
            onRequestClose();
          }
        }}
        style={{ ...style }}
      >
        <div
          className={classNames(
            'base-modal__wrapper',
            {
              'base-modal__wrapper--desktop': !isMobile,
              'base-modal__content-full': isMobile && mobileHeightType === 'full',
              'base-modal__content-auto': isMobile && mobileHeightType === 'auto',
              'base-modal__content-broken': isMobile && mobileHeightType === 'broken',
            },
            className
          )}
          style={{ ...wrapperStyle }}
        >
          {!notShowTitleBlank ? (
            <div className="base-modal__title">
              {title ? (
                <Typography
                  component="div"
                  variant={isMobile ? 'body2' : 'h7'}
                  fontWeight={600}
                  textAlign={titleAlign}
                >
                  {title}
                </Typography>
              ) : (
                <div className="base-modal__title-empty"></div>
              )}
            </div>
          ) : (
            <div className="base-modal__title-empty" style={{ ...titleEmptyStyle }}></div>
          )}
          {onClose && (
            <Close
              fill="var(--color-purple_4)"
              className="base-modal__close"
              onClick={e => {
                e.nativeEvent.stopImmediatePropagation();
                onClose();
              }}
            />
          )}
          <div className="base-modal__children" style={{ ...childrenWrapperStyle }}>
            {children}
          </div>
          {!!bottomContent && <div className="bottom">{bottomContent}</div>}
        </div>
      </ReactModal>
      <style jsx>
        {`
          .bottom {
            position: absolute;
            bottom: 0;
            width: 100%;
            display: flex;
            justify-content: flex-end;
            flex-direction: row;
            border-top: 1px solid var(--color-purple_2);
            padding: ${isMobile ? '8px ' : '16px'};
            text-align: right;
            background: var(--color-white);
            border-radius: 0 0 14px 14px;
          }
        `}
      </style>
      <style global jsx>{`
        .base-modal {
          &__overlay {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100vh;
            z-index: 9998; /** tooltip zIndex is 9999 **/
            background-color: rgba(76, 76, 76, 0.6);
          }
          &__content {
            width: 100%;
            height: 100%;
            position: relative;
          }
          &__wrapper {
            border-radius: 14px;
            background-color: var(--color-white);
            position: absolute;
            top: 50%;
            left: 50%;
            -webkit-transform: translate(-50%, -50%);
            -moz-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            width: 800px;
            display: flex;
            flex-direction: column;
            &--desktop {
              max-height: 90%;
            }
          }
          &__content-full {
            height: 100%;
          }
          &__children {
            padding: 56px;
            flex: 1 1;
            overflow-y: auto;
          }
          &__title {
            padding: 18px 0;
            border-bottom: 1px solid var(--color-purple_2);
          }
          &__title-empty {
            height: 24px;
          }
          &__close {
            position: absolute;
            right: 22px;
            top: 22px;
            width: 18px;
            height: 18px;
            cursor: pointer;
          }
        }
        @media screen and (max-width: 768px) {
          .base-modal {
            &__wrapper {
              border-radius: 0;
              width: 100%;
              position: relative;
              top: unset;
              left: unset;
              -webkit-transform: none;
              -moz-transform: none;
              transform: none;
            }
            &__content-broken {
              position: absolute;
              height: 85%;
              top: unset;
              left: 0;
              bottom: 0;
              transform: none;
            }
            &__content-auto {
              position: absolute;
              height: auto;
              top: unset;
              left: 0;
              bottom: 0;
              transform: none;
            }
            &__title {
              padding: 12px 36px 12px 16px;
            }
            &__children {
              padding: 16px;
            }
            &__close {
              right: 18px;
              top: 18px;
              width: 16px;
              height: 16px;
            }
          }
        }
      `}</style>
    </>
  );
};

export default BaseModal;
