import { UAContext } from '@quentin-sommer/react-useragent';
import React, { useContext } from 'react';
import ReactModal from 'react-modal';
import Button from '~components/button';
import Typography from '~components/typography';
import {
  Close,
  GenderMismatch,
  IconCaution,
  IconPay,
  IconSuccess,
  IconUnderage,
} from '~svg-components';

interface IAlertProps {
  isOpen: boolean;
  type: 'success' | 'caution' | 'underage' | 'payment' | 'gender';
  title: string;
  content: React.ReactNode;
  btnText?: string;
  height?: string;
  isShowClose?: boolean;
  onConfirm?: (e: React.MouseEvent) => void;
  onClose?: () => void;
  loading?: boolean;
}

const Confirm: React.FC<IAlertProps & React.HTMLAttributes<HTMLDivElement>> = props => {
  const uaResults = useContext(UAContext).uaResults as UAResults;
  const isMobile = uaResults.mobile;

  const {
    title,
    type,
    content,
    btnText,
    height,
    isOpen,
    onClose,
    onConfirm,
    isShowClose,
    loading,
  } = props;

  return (
    <>
      <ReactModal
        isOpen={isOpen}
        onRequestClose={onClose}
        // onAfterOpen={() => {
        //   document.body.style.position = 'fixed';
        //   document.body.style.overflow = 'hidden';
        //   document.body.style.width = '100%';
        // }}
        // onAfterClose={() => {
        //   document.body.style.position = 'static';
        //   document.body.style.overflow = 'initial';
        // }}
        overlayClassName="confirm-modal__overlay"
        className="confirm-modal"
      >
        <div className="confirm-modal__wrapper">
          {isShowClose && (
            <Close
              fill="var(--color-purple_4)"
              className="confirm-close"
              onClick={() => {
                if (onClose) {
                  onClose();
                }
              }}
            />
          )}
          <div className="confirmType">
            {type === 'success' && <IconSuccess width={isMobile ? 56 : 82} />}
            {type === 'caution' && <IconCaution width={isMobile ? 56 : 82} />}
            {type === 'underage' && <IconUnderage width={isMobile ? 56 : 70} />}
            {type === 'payment' && <IconPay width={isMobile ? 56 : 75} />}
            {type === 'gender' && <GenderMismatch width={isMobile ? 56 : 82} />}
          </div>
          <div className="confirmContent">
            <Typography component="p" variant={isMobile ? 'h7' : 'h5'}>
              {title}
            </Typography>
            {typeof content === 'string' ? (
              <Typography
                component="p"
                variant={isMobile ? 'body3' : 'body1'}
                style={{ marginTop: 8 }}
              >
                {content}
              </Typography>
            ) : (
              content
            )}
            {btnText && (
              <Button
                size={isMobile ? 'default' : 'large'}
                onClick={onConfirm}
                loading={loading}
                style={{ marginTop: 24 }}
              >
                {btnText}
              </Button>
            )}
          </div>
        </div>
      </ReactModal>
      <style jsx>
        {`
          .confirm-modal__wrapper {
            width: 648px;
            padding: 68px;
            border-radius: 4px;
            border-top: 8px solid var(--color-yellow_6);
            background-color: var(--color-white);
            position: absolute;
            top: 50%;
            left: 50%;
            -webkit-transform: translate(-50%, -50%);
            -moz-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            background-image: linear-gradient(
              to bottom,
              var(--color-yellow_1),
              var(--color-white) 16%,
              var(--color-white)
            );
          }
          :global(.confirm-modal) {
            width: 100%;
            height: 100%;
            position: relative;
          }
          :global(.confirm-modal__overlay) {
            position: fixed;
            top: 0px;
            left: 0px;
            right: 0px;
            bottom: 0px;
            z-index: 50000000;
            background-color: rgba(76, 76, 76, 0.6);
          }
          .confirmType {
            text-align: center;
          }
          .confirmContent {
            text-align: center;
            padding-top: 25px;
          }
          :global(.confirm-close) {
            width: 20px;
            height: 20px;
            cursor: pointer;
            position: absolute;
            top: 16px;
            right: 20px;
          }
          @media screen and (max-width: 768px) {
            :global(.confirm-modal__overlay) {
              padding: 0 16px;
            }
            .confirm-modal__wrapper {
              width: 100%;
              padding: 48px 24px;
            }
            .confirmContent {
              padding-top: 20px;
            }
          }
        `}
      </style>
    </>
  );
};

export default Confirm;
