import cn from 'classnames';
import React from 'react';
import Typography from '~components/typography';
import { More } from '~svg-components';
interface IPanelProps {
  title: React.ReactNode;
  isOpen: boolean;
  onClose?: () => void;
}
const Confirm: React.FC<IPanelProps & React.HTMLAttributes<HTMLDivElement>> = ({
  title,
  isOpen,
  onClose,
  children,
}) => {
  return (
    <div
      className={cn('panel-component', {
        'panel-component--show': isOpen,
      })}
    >
      <div className="panel-component__title">
        <More
          width="15"
          height="18"
          className="panel-component__back"
          fill="var(--color-greyText)"
          onClick={() => {
            if (onClose) {
              onClose();
            }
          }}
        />
        <Typography variant="body2" fontWeight={600}>
          {title}
        </Typography>
      </div>
      {children}
      <style jsx>{`
        .panel-component {
          width: 100%;
          height: 100%;
          background: var(--color-white);
          position: fixed;
          top: 0;
          left: 100%;
          transition: left 0.3s ease-out;
          &--show {
            left: 0;
          }
          :global(&__back) {
            transform: rotate(90deg);
            position: absolute;
            left: 16px;
            top: 14px;
          }
          &__title {
            width: 100%;
            height: 48px;
            box-shadow: 0 1px 0 0 var(--color-outline);
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
          }
        }
      `}</style>
    </div>
  );
};

export default Confirm;
