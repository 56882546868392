import gql from 'graphql-tag';

const FRAGMENT_STUDENT_INFO = gql`
  fragment fragmentPersonDetailQueryStudentInfo on Student {
    id
    originId
    email
    gender
    account {
      id
      originId
      email
      editInfo {
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
    }
    firstName
    middleName
    lastName
  }
`;

export const QUERY_COUNTRIES = gql`
  query SP_QueryCountries($nameLike: String) {
    getCountries(nameLike: $nameLike) {
      countries {
        id
        name
        slug
        countryCode
        currencyCode
        dialingCountryCode
        nationality
      }
    }
  }
`;

export const QUERY_UNIVERSITIES = gql`
  query SP_QueryUniversities($nameLike: String, $cityId: ID) {
    getUniversities(nameLike: $nameLike, cityId: $cityId) {
      universities {
        id
        originId
        name
        slug
        latitude
        longitude
      }
    }
  }
`;

export const QUERY_CITIES = gql`
  query SP_QueryCities($nameLike: String, $countryId: ID) {
    getCities(nameLike: $nameLike, countryId: $countryId) {
      cities {
        id
        originId
        name
        slug
        latitude
        longitude
      }
    }
  }
`;

export const MUTATION_PERSONAL_DETAIL = gql`
  mutation SP_MutationPersonalDetail($input: studentFillPersonalDetailInput!) {
    studentFillPersonalDetail(input: $input) {
      id
      countDown
      contracts {
        id
        name
        content
      }
      student {
        ...fragmentPersonDetailQueryStudentInfo
      }
    }
  }
  ${FRAGMENT_STUDENT_INFO}
`;

export const AMENDMENT_MUTATION_PERSONAL_DETAIL = gql`
  mutation SP_AmendmentMutationPersonalDetail($input: studentFillPersonalDetailInput!) {
    studentFillPersonalDetailForAmendment(input: $input) {
      id
      countDown
      contracts {
        id
        name
        content
      }
      student {
        ...fragmentPersonDetailQueryStudentInfo
      }
    }
  }
  ${FRAGMENT_STUDENT_INFO}
`;

export const MUTATION_STUDENT_CANCEL_BOOKING_COUNTDOWN = gql`
  mutation SP_MutationStudentCancelBookingCountDown($bookingId: ID!) {
    studentCancelBookingCountDown(bookingId: $bookingId) {
      status
    }
  }
`;

export const QUERY_FIELD_SETTINGS_BY_MODEL = gql`
  query SP_QueryFieldSettingByModel($topLandlordId: ID!, $model: CustomFieldModelTypeOptions!) {
    getFieldSettingsByModel(topLandlordId: $topLandlordId, model: $model) {
      id
      modelType
      fieldFormat
      type
      labelName
      apiFieldName
      picklistOptions {
        name
        value
      }
      fieldName
      inputTypeInForm
    }
  }
`;
