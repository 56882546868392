import TagManager from 'react-gtm-module';

function gtmDataLayer(layerValue: any) {
  const tagManagerArgs = {
    dataLayer: layerValue,
  };
  TagManager.dataLayer(tagManagerArgs);
}

export { gtmDataLayer };
